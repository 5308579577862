import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import type { Guide } from '../types/database';

export function GuideList() {
  const [guides, setGuides] = useState<Guide[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { channelId } = useParams();

  useEffect(() => {
    fetchGuides();
  }, [channelId]);

  async function fetchGuides() {
    try {
      setLoading(true);
      setError(null);
      
      try {
        let query = supabase
          .from('recordings')
          .select(`
            *,
            creator:creator_id(display_name)
          `);

        if (channelId) {
          const { data: permissions } = await supabase
            .from('recording_permissions')
            .select('recording_id')
            .eq('channel_id', channelId);

          if (permissions && permissions.length > 0) {
            const recordingIds = permissions.map(p => p.recording_id);
            query = query.in('id', recordingIds);
          }
        } else {
          query = query.eq('organization_id', '00000000-0000-0000-0000-000000000000');
        }

        const { data, error } = await query.order('created_at', { ascending: false });

        if (error) {
          throw error;
        }

        if (data && data.length > 0) {
          setGuides(data);
          return;
        }
      } catch (joinError) {
        console.error('Error with join query, trying fallback:', joinError);
      }

      try {
        let simpleQuery = supabase
          .from('recordings')
          .select('*');

        if (channelId) {
          const { data: permissions } = await supabase
            .from('recording_permissions')
            .select('recording_id')
            .eq('channel_id', channelId);

          if (permissions && permissions.length > 0) {
            const recordingIds = permissions.map(p => p.recording_id);
            simpleQuery = simpleQuery.in('id', recordingIds);
          }
        } else {
          simpleQuery = simpleQuery.eq('organization_id', '00000000-0000-0000-0000-000000000000');
        }

        const { data, error } = await simpleQuery.order('created_at', { ascending: false });

        if (error) {
          throw error;
        }

        setGuides(data || []);
      } catch (fallbackError) {
        console.error('Even fallback query failed:', fallbackError);
        throw fallbackError;
      }
    } catch (error: any) {
      console.error('Error fetching guides:', error);
      setError(error?.message || 'Failed to load guides');
      setGuides([]);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="flex flex-col items-center gap-4">
          <div className="relative">
            <div className="h-12 w-12 rounded-full border-t-2 border-b-2 border-gray-900 animate-spin"></div>
            <div className="absolute inset-0 h-12 w-12 rounded-full border-t-2 border-b-2 border-gray-300 animate-spin" style={{ animationDirection: 'reverse', animationDuration: '1.5s' }}></div>
          </div>
          <p className="text-sm text-gray-600">Loading guides...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6 rounded-r-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error loading guides</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
                <button 
                  className="mt-2 px-4 py-2 bg-red-100 text-red-800 rounded-lg hover:bg-red-200 transition-colors duration-200"
                  onClick={() => fetchGuides()}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-3 sm:p-4 md:p-6 max-w-[1600px] mx-auto">
      <div className="mb-4 sm:mb-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
              {channelId ? 'Channel Guides' : 'Welcome to ShowMeHow'}
            </h1>
            <p className="mt-2 text-sm sm:text-base text-gray-600">
              {channelId 
                ? `${guides.length} guides available in this channel`
                : 'Explore our collection of interactive AI-powered guides to help you learn new skills'}
            </p>
          </div>
          <div className="flex items-center gap-2 sm:gap-4">
            <span className="text-sm text-gray-500">View as:</span>
            <button className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
              </svg>
            </button>
            <button className="p-2 text-gray-400 hover:bg-gray-50 rounded-lg transition-colors">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-3 sm:gap-4 md:gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {guides.map((guide) => (
          <Link
            key={guide.id}
            to={`/guide/${guide.id}`}
            className="group bg-white rounded-lg sm:rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 overflow-hidden border border-gray-100 flex flex-col h-full"
          >
            {/* Card Content */}
            <div className="p-3 sm:p-4 flex-grow">
              {/* Top metadata row */}
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center gap-2 flex-wrap">
                  <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700">
                    <svg className="h-3 w-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    {(guide as any).duration_seconds ? Math.floor((guide as any).duration_seconds / 60) : 0} min
                  </span>
                </div>
                <button 
                  onClick={(e) => {
                    e.preventDefault();
                    // Add to favorites functionality
                  }}
                  className="text-gray-400 hover:text-yellow-500 transition-colors p-1 hover:bg-gray-50 rounded-full"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                  </svg>
                </button>
              </div>

              {/* Title and Description */}
              <div className="space-y-2 mb-3">
                <h3 className="text-base sm:text-lg font-semibold text-gray-900 group-hover:text-blue-600 transition-colors duration-200 line-clamp-2">
                  {guide.title}
                </h3>
                <p className="text-sm text-gray-600 line-clamp-2 font-light">
                  {guide.description || 'No description available'}
                </p>
              </div>

              {/* Tags */}
              {guide.tags && Array.isArray(guide.tags) && guide.tags.length > 0 && (
                <div className="flex flex-wrap gap-1.5">
                  {guide.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-50 text-gray-600 group-hover:bg-gray-100"
                    >
                      #{tag}
                    </span>
                  ))}
                </div>
              )}
            </div>

            {/* Card Footer */}
            <div className="mt-auto border-t border-gray-50">
              {/* Creator Info */}
              <div className="p-3 sm:p-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="h-6 w-6 rounded-full bg-gradient-to-br from-blue-100 to-blue-200 flex items-center justify-center">
                    <svg className="h-3 w-3 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </div>
                  <span className="text-xs text-gray-600 font-medium truncate">
                    {(guide as any).creator?.display_name || (guide as any).creator || 'Anonymous'}
                  </span>
                </div>
                <svg className="h-4 w-4 text-blue-600 transform group-hover:translate-x-1 transition-transform duration-200" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {guides.length === 0 && !error && (
        <div className="flex flex-col items-center justify-center min-h-[200px] sm:min-h-[300px] bg-white rounded-lg sm:rounded-xl border border-gray-100 p-4 sm:p-6">
          <div className="relative">
            <div className="absolute -bottom-8 -right-8 w-24 sm:w-32 h-24 sm:h-32 bg-blue-100 rounded-full filter blur-2xl opacity-10"></div>
            <svg
              className="relative h-10 w-10 sm:h-12 sm:w-12 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
              />
            </svg>
          </div>
          <h3 className="mt-4 text-base font-medium text-gray-900">No guides found</h3>
          <p className="mt-2 text-center text-sm text-gray-600">
            {channelId
              ? 'This channel doesn\'t have any guides yet. Create one to get started!'
              : 'There are no public guides available at the moment. Check back later for new content!'}
          </p>
          <button
            onClick={() => fetchGuides()}
            className="mt-4 px-4 py-2 bg-blue-600 text-white text-sm rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
          >
            Refresh Guides
          </button>
        </div>
      )}
    </div>
  );
} 