import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ChannelSidebar } from '../components/ChannelSidebar';
import { Link, useNavigate, useLocation } from 'react-router-dom';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export function DashboardLayout({ children }: DashboardLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const profileRef = useRef<HTMLDivElement>(null);
  
  // Determine if we're in creation mode based on the URL
  const isCreationMode = location.pathname.includes('/guide');

  // Function to switch between modes
  const toggleMode = () => {
    if (isCreationMode) {
      // If in creation mode, go to learning mode
      navigate('/learn');
    } else {
      // If in learning mode, go to creation mode
      navigate('/guide');
    }
  };

  // Close profile dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setIsProfileOpen(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileRef]);

  // Handle sign out
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/learn');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Get user display name (first part of email or full email)
  const getDisplayName = () => {
    if (!user?.email) return 'User';
    return user.email.split('@')[0];
  };

  // Get user initials for avatar
  const getUserInitials = () => {
    if (!user?.email) return 'U';
    const name = user.email.split('@')[0];
    return name.charAt(0).toUpperCase();
  };

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-white">
      {/* Mode Switch Header */}
      <div className="h-16 flex-shrink-0 flex items-center justify-between px-4 bg-white border-b border-gray-200 z-10">
        <div className="flex items-center">
          <h1 className="text-xl font-bold text-gray-900">ShowMeHow</h1>
        </div>
        
        {/* Mode Switch Toggle */}
        <div className="flex flex-col sm:flex-row items-center gap-2 mx-auto w-full sm:w-auto px-4 sm:px-0">
          <button
            onClick={toggleMode}
            className={`relative inline-flex w-full sm:w-auto items-center justify-center px-6 py-3 sm:py-2 rounded-lg transition-all duration-300 ${
              isCreationMode ? 'bg-gray-200' : 'bg-blue-600 text-white'
            }`}
            aria-label="Show Me How"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
            </svg>
            Show Me How
          </button>
          <button
            onClick={toggleMode}
            className={`relative inline-flex w-full sm:w-auto items-center justify-center px-6 py-3 sm:py-2 rounded-lg transition-all duration-300 ${
              isCreationMode ? 'bg-blue-600 text-white' : 'bg-gray-200'
            }`}
            aria-label="Show Them How"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Show Them How
          </button>
        </div>
        
        {/* User Profile Menu */}
        <div className="relative" ref={profileRef}>
          {user ? (
            <>
              {/* Authenticated User */}
              <button
                onClick={() => setIsProfileOpen(!isProfileOpen)}
                className="flex items-center focus:outline-none"
                aria-expanded={isProfileOpen}
                aria-haspopup="true"
              >
                <span className="hidden md:block mr-2 text-sm font-medium text-gray-700">
                  {getDisplayName()}
                </span>
                <div className="h-9 w-9 rounded-full bg-blue-600 text-white flex items-center justify-center text-sm font-medium">
                  {getUserInitials()}
                </div>
              </button>

              {/* Profile Dropdown */}
              {isProfileOpen && (
                <div className="absolute right-0 w-48 mt-2 py-2 bg-white rounded-md shadow-lg z-20">
                  <div className="px-4 py-2 border-b border-gray-100">
                    <p className="text-sm font-medium text-gray-900">{user.email}</p>
                    <p className="text-xs text-gray-500 truncate">Account Settings</p>
                  </div>
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsProfileOpen(false)}
                  >
                    <div className="flex items-center">
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                      Your Profile
                    </div>
                  </Link>
                  <Link
                    to="/channels/manage"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsProfileOpen(false)}
                  >
                    <div className="flex items-center">
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                      </svg>
                      My Channels
                    </div>
                  </Link>
                  <div className="border-t border-gray-100 mt-1"></div>
                  <button
                    onClick={handleSignOut}
                    className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                      Sign out
                    </div>
                  </button>
                </div>
              )}
            </>
          ) : (
            /* Unauthenticated User */
            <div className="flex items-center space-x-2">
              <Link
                to="/signin"
                className="px-4 py-2 text-sm font-medium text-blue-600 border border-blue-600 rounded-md hover:bg-blue-50 transition-colors"
              >
                Sign In
              </Link>
              <Link
                to="/signup"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
              >
                Sign Up
              </Link>
            </div>
          )}
        </div>
      </div>
      
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className={`
          ${isSidebarOpen ? 'w-64' : 'w-20'}
          flex-shrink-0 transition-all duration-300 ease-in-out
          border-r border-gray-200 bg-gray-50
        `}>
          <div className="h-full flex flex-col">
            {/* Sidebar Header */}
            <div className="h-16 flex-shrink-0 flex items-center justify-between px-4 border-b border-gray-200">
              <h2 className={`font-semibold text-gray-800 ${!isSidebarOpen && 'sr-only'}`}>
                Learning Channels
              </h2>
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="p-2 rounded-md text-gray-500 hover:bg-gray-200"
              >
                {isSidebarOpen ? (
                  <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                  </svg>
                ) : (
                  <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                  </svg>
                )}
              </button>
            </div>

            {/* Channel Sidebar */}
            <ChannelSidebar isOpen={isSidebarOpen} />

            {/* This section is now redundant since we added the profile menu to the header */}
            {/* It can be removed or repurposed for other sidebar content */}
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
} 