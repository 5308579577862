/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export type GetAudioContextOptions = AudioContextOptions & {
  id?: string;
};

const map: Map<string, AudioContext> = new Map();

export const audioContext: (
  options?: GetAudioContextOptions,
) => Promise<AudioContext> = (() => {
  const didInteract = new Promise((res) => {
    window.addEventListener("pointerdown", res, { once: true });
    window.addEventListener("keydown", res, { once: true });
  });

  return async (options?: GetAudioContextOptions) => {
    try {
      const a = new Audio();
      a.src =
        "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA";
      await a.play();
      if (options?.id && map.has(options.id)) {
        const ctx = map.get(options.id);
        if (ctx) {
          return ctx;
        }
      }
      const ctx = new AudioContext(options);
      if (options?.id) {
        map.set(options.id, ctx);
      }
      return ctx;
    } catch (e) {
      await didInteract;
      if (options?.id && map.has(options.id)) {
        const ctx = map.get(options.id);
        if (ctx) {
          return ctx;
        }
      }
      const ctx = new AudioContext(options);
      if (options?.id) {
        map.set(options.id, ctx);
      }
      return ctx;
    }
  };
})();

export const blobToJSON = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const json = JSON.parse(reader.result as string);
        resolve(json);
      } else {
        reject("oops");
      }
    };
    reader.readAsText(blob);
  });

export function base64ToArrayBuffer(base64: string) {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

/**
 * Calculates the duration of a video blob in seconds
 * @param videoBlob The video blob to calculate duration for
 * @returns A promise that resolves to the duration in seconds
 */
export const calculateVideoDuration = (videoBlob: Blob): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';
    
    // Set a timeout in case the metadata never loads
    const timeout = setTimeout(() => {
      window.URL.revokeObjectURL(videoElement.src);
      
      // Fallback to file size estimation
      const estimatedDuration = estimateDurationFromFileSize(videoBlob);
      resolve(estimatedDuration);
    }, 10000); // 10 second timeout
    
    // Function to check if duration is valid
    const isDurationValid = (duration: number): boolean => {
      return !isNaN(duration) && isFinite(duration) && duration > 0;
    };
    
    // Load metadata event
    videoElement.onloadedmetadata = () => {
      // Try to seek to the end to get accurate duration
      try {
        videoElement.currentTime = 1000000; // Seek to a very large time to force seeking to the end
        
        // Wait a bit for seeking to complete
        setTimeout(() => {
          if (isDurationValid(videoElement.duration)) {
            clearTimeout(timeout);
            window.URL.revokeObjectURL(videoElement.src);
            resolve(videoElement.duration);
          } else {
            // If still not valid, use file size estimation
            clearTimeout(timeout);
            window.URL.revokeObjectURL(videoElement.src);
            const estimatedDuration = estimateDurationFromFileSize(videoBlob);
            resolve(estimatedDuration);
          }
        }, 500);
      } catch (e) {
        // If seeking fails, use file size estimation
        clearTimeout(timeout);
        window.URL.revokeObjectURL(videoElement.src);
        const estimatedDuration = estimateDurationFromFileSize(videoBlob);
        resolve(estimatedDuration);
      }
    };
    
    videoElement.onerror = () => {
      clearTimeout(timeout);
      window.URL.revokeObjectURL(videoElement.src);
      
      // Fallback to file size estimation
      const estimatedDuration = estimateDurationFromFileSize(videoBlob);
      resolve(estimatedDuration);
    };
    
    const objectUrl = URL.createObjectURL(videoBlob);
    videoElement.src = objectUrl;
    
    // For some browsers, we need to explicitly load the video
    videoElement.load();
  });
};

/**
 * Estimates video duration based on file size and assumed bitrate
 * This is a fallback method when other methods fail
 * 
 * @param blob The video blob
 * @returns Estimated duration in seconds
 */
export const estimateDurationFromFileSize = (blob: Blob): number => {
  // WebM with VP9 video + Opus audio typical bitrates (in bits per second)
  // These are rough estimates and actual bitrates can vary significantly
  const typicalBitrates = {
    lowQuality: 500000,    // 500 Kbps - low quality screen recording
    mediumQuality: 1000000, // 1 Mbps - medium quality screen recording
    highQuality: 2500000,   // 2.5 Mbps - high quality screen recording
  };
  
  // Use medium quality as default assumption
  const assumedBitrate = typicalBitrates.mediumQuality;
  
  // Calculate bytes per second based on assumed bitrate
  const bytesPerSecond = assumedBitrate / 8;
  
  // Calculate estimated duration
  const estimatedDuration = blob.size / bytesPerSecond;
  
  // Ensure we return a reasonable value (between 1 second and 2 hours)
  const clampedDuration = Math.min(Math.max(estimatedDuration, 1), 7200);
  
  return clampedDuration;
};

/**
 * Formats seconds into a MM:SS format
 * @param seconds The number of seconds to format
 * @returns A string in MM:SS format
 */
export const formatDuration = (seconds: number | null): string => {
  if (seconds === null || isNaN(seconds)) return '00:00';
  
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

/**
 * Checks if a WebM file has audio tracks by parsing its header
 * @param blob The WebM video blob
 * @returns A promise that resolves to true if audio tracks are found, false otherwise
 */
export const checkWebMForAudioTracks = async (blob: Blob): Promise<boolean | null> => {
  try {
    // Read the first 8KB of the file which should contain the header with track info
    const buffer = await blob.slice(0, 8192).arrayBuffer();
    const view = new DataView(buffer);
    
    // WebM file starts with EBML header (1A 45 DF A3)
    if (view.getUint32(0) !== 0x1A45DFA3) {
      return null;
    }
    
    // Search for the TrackType element (0x83)
    // Track type 1 = video, 2 = audio
    let foundAudioTrack = false;
    
    for (let i = 0; i < buffer.byteLength - 2; i++) {
      // Look for TrackType element ID (0x83)
      if (view.getUint8(i) === 0x83) {
        // Get the value (should be 1 byte)
        // Skip 1 byte for element ID and 1 byte for size
        const trackType = view.getUint8(i + 2);
        
        // Track type 2 is audio
        if (trackType === 2) {
          foundAudioTrack = true;
          break;
        }
      }
    }
    
    return foundAudioTrack;
  } catch (error) {
    return null;
  }
};
