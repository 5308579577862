import { useState, useEffect } from 'react';
import { getMyChannels } from '../../lib/channels';
import type { Channel } from '../../types/database';

interface ChannelSelectorProps {
  onChannelSelect: (channelIds: string[]) => void;
  selectedChannels?: string[];
  className?: string;
}

export function ChannelSelector({ onChannelSelect, selectedChannels = [], className = '' }: ChannelSelectorProps) {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selected, setSelected] = useState<Set<string>>(new Set(selectedChannels));

  useEffect(() => {
    loadChannels();
  }, []);

  async function loadChannels() {
    try {
      setLoading(true);
      setError(null);
      const data = await getMyChannels();
      setChannels(data);
    } catch (err) {
      setError('Failed to load channels');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const handleChannelToggle = (channelId: string) => {
    const newSelected = new Set(selected);
    if (newSelected.has(channelId)) {
      newSelected.delete(channelId);
    } else {
      newSelected.add(channelId);
    }
    setSelected(newSelected);
    onChannelSelect(Array.from(newSelected));
  };

  if (loading) {
    return (
      <div className={`flex items-center justify-center p-4 ${className}`}>
        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`text-red-600 text-sm ${className}`}>
        {error}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="text-sm font-medium text-gray-700 mb-2">
        Share with channels:
      </div>
      <div className="space-y-2">
        {channels.map(channel => (
          <label key={channel.id} className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={selected.has(channel.id)}
              onChange={() => handleChannelToggle(channel.id)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-sm text-gray-700">{channel.name}</span>
          </label>
        ))}
        {channels.length === 0 && (
          <p className="text-sm text-gray-500">
            No channels available. Create a channel first to share recordings privately.
          </p>
        )}
      </div>
    </div>
  );
} 