import { useState, useCallback, useRef, useEffect } from 'react';
import { useLiveAPIContext } from '../../contexts/LiveAPIContext';
import { useWebcam } from '../../hooks/use-webcam';
import { useScreenCapture } from '../../hooks/use-screen-capture';
import { AudioRecorder } from '../../lib/audio-recorder';
import { UseMediaStreamResult } from '../../hooks/use-media-stream-mux';

export type ShowMeHowState = 'idle' | 'connecting' | 'active' | 'error';
export type VideoSource = 'webcam' | 'screen' | null;

interface UseShowMeHowResult {
  state: ShowMeHowState;
  startSession: () => Promise<void>;
  endSession: () => Promise<void>;
  error: Error | null;
  videoRef: React.RefObject<HTMLVideoElement>;
  renderCanvasRef: React.RefObject<HTMLCanvasElement>;
  toggleVideoSource: () => Promise<void>;
  toggleMute: () => void;
  muted: boolean;
  inVolume: number;
  currentVideoSource: VideoSource;
}

export function useShowMeHow(): UseShowMeHowResult {
  const [state, setState] = useState<ShowMeHowState>('idle');
  const [error, setError] = useState<Error | null>(null);
  const [activeVideoStream, setActiveVideoStream] = useState<MediaStream | null>(null);
  const [currentVideoSource, setCurrentVideoSource] = useState<VideoSource>(null);
  const [inVolume, setInVolume] = useState(0);
  const [audioRecorder] = useState(() => new AudioRecorder());
  const [muted, setMuted] = useState(false);
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const renderCanvasRef = useRef<HTMLCanvasElement>(null);
  
  const { setConfig, connected, client, connect, disconnect } = useLiveAPIContext();
  const webcam = useWebcam();
  const screenCapture = useScreenCapture();

  // Handle video frame sending
  useEffect(() => {
    if (!connected || !activeVideoStream || !videoRef.current) return;

    let timeoutId = -1;

    function sendVideoFrame() {
      const video = videoRef.current;
      const canvas = renderCanvasRef.current;

      if (!video || !canvas || !client) return;

      const ctx = canvas.getContext('2d')!;
      canvas.width = video.videoWidth * 0.25;
      canvas.height = video.videoHeight * 0.25;
      
      if (canvas.width + canvas.height > 0) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const base64 = canvas.toDataURL('image/jpeg', 1.0);
        const data = base64.slice(base64.indexOf(',') + 1);
        client.sendRealtimeInput([{ mimeType: 'image/jpeg', data }]);
      }

      if (connected) {
        timeoutId = window.setTimeout(sendVideoFrame, 1000 / 0.5);
      }
    }

    requestAnimationFrame(sendVideoFrame);
    return () => clearTimeout(timeoutId);
  }, [connected, activeVideoStream, client]);

  // Handle audio recording
  useEffect(() => {
    if (!connected || !client) return;

    const onData = (base64: string) => {
      client.sendRealtimeInput([
        {
          mimeType: 'audio/pcm;rate=16000',
          data: base64,
        },
      ]);
    };

    if (connected && !muted && audioRecorder) {
      audioRecorder.on('data', onData).on('volume', setInVolume).start();
    } else {
      audioRecorder.stop();
    }

    return () => {
      audioRecorder.off('data', onData).off('volume', setInVolume);
    };
  }, [connected, client, muted, audioRecorder]);

  const startVideoStream = async (streamProvider: UseMediaStreamResult, source: VideoSource) => {
    try {
      // Stop any existing streams first
      stopVideoStream();
      
      const mediaStream = await streamProvider.start();
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        await videoRef.current.play(); // Ensure video starts playing
      }
      setActiveVideoStream(mediaStream);
      setCurrentVideoSource(source);
    } catch (err) {
      console.error('Failed to start video stream:', err);
      throw err;
    }
  };

  const stopVideoStream = () => {
    if (activeVideoStream) {
      activeVideoStream.getTracks().forEach(track => track.stop());
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setActiveVideoStream(null);
    setCurrentVideoSource(null);
    webcam.stop();
    screenCapture.stop();
  };

  const startSession = useCallback(async () => {
    try {
      setState('connecting');
      setError(null);

      // Initialize all services in parallel
      await Promise.all([
        // Initialize Gemini Live
        (async () => {
          await setConfig({
            model: "models/gemini-2.0-flash-exp",
            systemInstruction: {
              parts: [
                {
                  text: "You are a helpful AI assistant that helps users learn new skills.",
                },
              ],
            },
          });
          await connect();
        })(),
        // Initialize screen sharing by default
        (async () => {
          await startVideoStream(screenCapture, 'screen');
        })(),
        // Initialize audio
        (() => {
          setMuted(false);
          return Promise.resolve();
        })(),
      ]);

      setState('active');
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to start session'));
      setState('error');
      // Clean up any partial initialization
      await endSession();
    }
  }, [setConfig, connect, screenCapture]);

  const endSession = useCallback(async () => {
    try {
      // Clean up all services
      stopVideoStream();
      audioRecorder.stop();
      setMuted(true);
      await disconnect();
      setState('idle');
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to end session'));
      setState('error');
    }
  }, [disconnect, audioRecorder]);

  // Toggle between webcam and screen capture
  const toggleVideoSource = async () => {
    if (!connected) return;

    try {
      // If currently using webcam, switch to screen capture, or vice versa
      if (currentVideoSource === 'webcam') {
        await startVideoStream(screenCapture, 'screen');
      } else {
        await startVideoStream(webcam, 'webcam');
      }
    } catch (err) {
      console.error('Failed to toggle video source:', err);
      setError(err instanceof Error ? err : new Error('Failed to toggle video source'));
    }
  };

  // Toggle mute state
  const toggleMute = () => {
    setMuted(!muted);
  };

  return {
    state,
    startSession,
    endSession,
    error,
    videoRef,
    renderCanvasRef,
    toggleVideoSource,
    toggleMute,
    muted,
    inVolume,
    currentVideoSource,
  };
} 