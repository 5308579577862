import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface Channel {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

export function ChannelManager() {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [loading, setLoading] = useState(true);
  const [newChannel, setNewChannel] = useState({ name: '', description: '' });
  const [editingChannel, setEditingChannel] = useState<Channel | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchUserChannels();
    }
  }, [user]);

  async function fetchUserChannels() {
    try {
      setLoading(true);
      const { data: channelMembers, error: memberError } = await supabase
        .from('channel_members')
        .select('channel_id')
        .eq('user_id', user?.id);

      if (memberError) throw memberError;

      const channelIds = channelMembers?.map(member => member.channel_id) || [];

      if (channelIds.length > 0) {
        const { data: channelsData, error: channelsError } = await supabase
          .from('channels')
          .select('*')
          .in('id', channelIds)
          .order('name');

        if (channelsError) throw channelsError;
        setChannels(channelsData || []);
      }
    } catch (error) {
      console.error('Error fetching channels:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCreateChannel(e: React.FormEvent) {
    e.preventDefault();
    try {
      if (!user) return;

      // Create new channel
      const { data: channel, error: channelError } = await supabase
        .from('channels')
        .insert([
          {
            name: newChannel.name,
            description: newChannel.description,
          },
        ])
        .select()
        .single();

      if (channelError) throw channelError;

      // Add creator as channel member
      const { error: memberError } = await supabase
        .from('channel_members')
        .insert([
          {
            channel_id: channel.id,
            user_id: user.id,
            role: 'admin',
          },
        ]);

      if (memberError) throw memberError;

      setNewChannel({ name: '', description: '' });
      fetchUserChannels();
    } catch (error) {
      console.error('Error creating channel:', error);
    }
  }

  async function handleUpdateChannel(e: React.FormEvent) {
    e.preventDefault();
    try {
      if (!editingChannel) return;

      const { error } = await supabase
        .from('channels')
        .update({
          name: editingChannel.name,
          description: editingChannel.description,
        })
        .eq('id', editingChannel.id);

      if (error) throw error;

      setEditingChannel(null);
      fetchUserChannels();
    } catch (error) {
      console.error('Error updating channel:', error);
    }
  }

  async function handleDeleteChannel(channelId: string) {
    try {
      const { error } = await supabase
        .from('channels')
        .delete()
        .eq('id', channelId);

      if (error) throw error;

      fetchUserChannels();
    } catch (error) {
      console.error('Error deleting channel:', error);
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Manage Channels</h1>
        <p className="mt-1 text-sm text-gray-500">
          Create and manage your channels
        </p>
      </div>

      {/* Create Channel Form */}
      <div className="bg-white shadow rounded-lg mb-8">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900">Create New Channel</h3>
          <form onSubmit={handleCreateChannel} className="mt-4 space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Channel Name
              </label>
              <input
                type="text"
                id="name"
                value={newChannel.name}
                onChange={(e) => setNewChannel({ ...newChannel, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={newChannel.description}
                onChange={(e) => setNewChannel({ ...newChannel, description: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Create Channel
            </button>
          </form>
        </div>
      </div>

      {/* Channel List */}
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900">Your Channels</h3>
          <div className="mt-4 space-y-4">
            {channels.length === 0 ? (
              <p className="text-sm text-gray-500">You haven't created any channels yet.</p>
            ) : (
              channels.map((channel) => (
                <div
                  key={channel.id}
                  className="border rounded-lg p-4 hover:bg-gray-50"
                >
                  {editingChannel?.id === channel.id ? (
                    <form onSubmit={handleUpdateChannel} className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Channel Name
                        </label>
                        <input
                          type="text"
                          value={editingChannel.name}
                          onChange={(e) => setEditingChannel({ ...editingChannel, name: e.target.value })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Description
                        </label>
                        <textarea
                          value={editingChannel.description}
                          onChange={(e) => setEditingChannel({ ...editingChannel, description: e.target.value })}
                          rows={3}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        />
                      </div>
                      <div className="flex space-x-2">
                        <button
                          type="submit"
                          className="inline-flex items-center px-3 py-1.5 border border-transparent rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setEditingChannel(null)}
                          className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  ) : (
                    <div>
                      <div className="flex items-center justify-between">
                        <h4 className="text-lg font-medium text-gray-900">{channel.name}</h4>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => setEditingChannel(channel)}
                            className="text-sm text-blue-600 hover:text-blue-500"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteChannel(channel.id)}
                            className="text-sm text-red-600 hover:text-red-500"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                      <p className="mt-1 text-sm text-gray-500">{channel.description}</p>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 