import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../ui/Button";
import { CheckIcon } from "../ui/icons/CheckIcon";

export function PricingPage() {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1 flex items-center justify-center">
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col items-center justify-center space-y-4 text-center mb-12">
              <div className="space-y-2 max-w-3xl">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                  Start Creating Guides Today
                </h1>
                <p className="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400 mx-auto">
                  Whether you're just getting started or ready to scale, we have a plan for you
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6 mt-12 md:grid-cols-2 lg:gap-8 max-w-5xl mx-auto">
              {/* Free Tier */}
              <div className="flex flex-col p-6 bg-white shadow-lg rounded-lg dark:bg-gray-850 border border-gray-200">
                <div className="space-y-2">
                  <h3 className="text-2xl font-bold">Free</h3>
                  <p className="text-gray-500 dark:text-gray-400">Perfect for getting started</p>
                  <div className="text-3xl font-bold">$0</div>
                </div>
                <div className="flex-1 space-y-4 mt-8">
                  <ul className="space-y-3">
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                      <span>Create up to 5 guides</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                      <span>Access to public guides</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                      <span>Basic AI assistance</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 text-green-500 mr-2" />
                      <span>Community support</span>
                    </li>
                  </ul>
                </div>
                <div className="mt-8">
                  <Link to="/signup">
                    <Button className="w-full">Get Started</Button>
                  </Link>
                </div>
              </div>

              {/* Business Tier */}
              <div className="flex flex-col p-6 bg-primary shadow-lg rounded-lg text-primary-foreground relative overflow-hidden">
                <div className="absolute top-0 right-0 bg-primary-foreground text-primary px-3 py-1 text-sm transform translate-y-0 rounded-bl">
                  Popular
                </div>
                <div className="space-y-2">
                  <h3 className="text-2xl font-bold">Business</h3>
                  <p className="opacity-90">For teams and organizations</p>
                  <div className="space-y-1">
                    <div className="text-3xl font-bold">Contact Us</div>
                    <p className="text-sm opacity-90">Custom pricing for your needs</p>
                  </div>
                </div>
                <div className="flex-1 space-y-4 mt-8">
                  <ul className="space-y-3">
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 mr-2" />
                      <span>Unlimited guide creation</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 mr-2" />
                      <span>Private channels</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 mr-2" />
                      <span>Advanced AI features</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 mr-2" />
                      <span>Team collaboration tools</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 mr-2" />
                      <span>Analytics and insights</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 mr-2" />
                      <span>Priority support</span>
                    </li>
                    <li className="flex items-center">
                      <CheckIcon className="h-5 w-5 mr-2" />
                      <span>Custom branding options</span>
                    </li>
                  </ul>
                </div>
                <div className="mt-8">
                  <a 
                    href="https://cal.com/andrewsiah+irismeng/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <Button variant="outline" className="w-full border-primary-foreground text-primary-foreground hover:bg-primary-foreground/10">
                      Contact Sales
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            {/* FAQ or Additional Info */}
            <div className="mt-12 text-center">
              <p className="text-gray-500 dark:text-gray-400">
                Need a custom solution? <Link to="/contact" className="text-primary underline">Get in touch</Link>
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
} 