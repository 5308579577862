import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../lib/supabase';

// Minimal interface for guide display
interface SimpleGuide {
  id: string;
  title: string;
  description?: string | null;
  estimated_duration?: number | null;
  tags?: string[] | null;
  created_at: string;
  creator_display_name?: string | null;
}

// Define a fixed public organization ID
const PUBLIC_ORG_ID = '00000000-0000-0000-0000-000000000000';

export function PublicGuideList() {
  const [guides, setGuides] = useState<SimpleGuide[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    fetchPublicGuides();
  }, [retryCount]);

  async function fetchPublicGuides() {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase
        .from('public_recordings')
        .select('*')
        .order('created_at', { ascending: false });
        
      if (error) {
        throw error;
      }
      
      setGuides(data || []);
    } catch (err) {
      console.error('Error fetching public guides:', err);
      setError('Failed to load public guides. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  const handleRetry = () => {
    setRetryCount(prev => prev + 1);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="flex flex-col items-center gap-4">
          <div className="relative">
            <div className="h-12 w-12 rounded-full border-t-2 border-b-2 border-gray-900 animate-spin"></div>
            <div className="absolute inset-0 h-12 w-12 rounded-full border-t-2 border-b-2 border-gray-300 animate-spin" style={{ animationDirection: 'reverse', animationDuration: '1.5s' }}></div>
          </div>
          <p className="text-sm text-gray-600">Loading guides...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
      {error && (
        <div className="mb-6 bg-red-50 border border-red-200 rounded-xl p-4">
          <div className="flex items-center">
            <svg className="h-5 w-5 text-red-400 mr-3" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
            <p className="text-sm text-red-600">{error}</p>
          </div>
          <button
            onClick={handleRetry}
            className="mt-2 text-sm text-red-600 hover:text-red-700 font-medium"
          >
            Try Again
          </button>
        </div>
      )}

      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-3">
          Interactive Learning Guides
        </h1>
        <p className="text-lg text-gray-600">
          Explore our collection of AI-powered guides or create your own to share knowledge.
        </p>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {/* Create Guide Card */}
        <Link
          to="/guide"
          className="group relative flex flex-col justify-center items-center p-6 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-2xl border-2 border-dashed border-blue-200 hover:border-blue-300 transition-all duration-300 hover:shadow-lg hover:scale-[1.02] overflow-hidden"
        >
          <div className="absolute inset-0 bg-blue-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          
          {/* Plus Icon */}
          <div className="mb-4 p-4 bg-white rounded-full shadow-md group-hover:shadow-lg transition-all duration-300 group-hover:scale-110">
            <svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
            </svg>
          </div>
          
          <h3 className="text-xl font-semibold text-gray-900 mb-2 text-center">
            Create New Guide
          </h3>
          <p className="text-sm text-gray-600 text-center max-w-[240px]">
            Share your knowledge by creating an interactive AI-powered guide
          </p>
          
          {/* Arrow indicator */}
          <div className="absolute bottom-4 right-4 transform translate-x-2 group-hover:translate-x-3 transition-transform">
            <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </div>
        </Link>

        {/* Guide Cards */}
        {guides.map((guide) => (
          <Link
            key={guide.id}
            to={`/guide/${guide.id}`}
            className="group bg-white rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 overflow-hidden border border-gray-100 flex flex-col"
          >
            {/* Card Content */}
            <div className="p-6 flex-grow">
              {/* Top metadata row */}
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  {guide.estimated_duration && (
                    <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-700">
                      <svg className="h-3 w-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      {guide.estimated_duration} min
                    </span>
                  )}
                </div>
                <div className="transform opacity-0 group-hover:opacity-100 group-hover:translate-x-0 translate-x-2 transition-all duration-300">
                  <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </div>
              </div>

              {/* Title and Description */}
              <div className="space-y-2">
                <h3 className="text-xl font-semibold text-gray-900 group-hover:text-blue-600 transition-colors duration-200 line-clamp-2">
                  {guide.title}
                </h3>
                <p className="text-sm text-gray-600 line-clamp-2">
                  {guide.description || 'No description available'}
                </p>
              </div>

              {/* Tags */}
              {guide.tags && guide.tags.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-4">
                  {guide.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-50 text-gray-600 group-hover:bg-gray-100 transition-colors"
                    >
                      #{tag}
                    </span>
                  ))}
                </div>
              )}
            </div>

            {/* Card Footer */}
            <div className="px-6 py-4 border-t border-gray-50 bg-gray-50/50">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="h-8 w-8 rounded-full bg-gradient-to-br from-blue-100 to-blue-200 flex items-center justify-center">
                    <svg className="h-4 w-4 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </div>
                  <span className="text-sm text-gray-600 font-medium">
                    {guide.creator_display_name || 'Anonymous'}
                  </span>
                </div>
                <span className="text-xs text-gray-500">
                  {new Date(guide.created_at).toLocaleDateString()}
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {guides.length === 0 && !error && (
        <div className="flex flex-col items-center justify-center min-h-[300px] bg-white rounded-2xl border border-gray-100 p-8">
          <div className="relative mb-4">
            <div className="absolute -inset-4 bg-blue-100 rounded-full filter blur-xl opacity-20"></div>
            <svg
              className="relative w-16 h-16 text-blue-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
              />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">No guides yet</h3>
          <p className="text-gray-600 text-center max-w-md mb-6">
            Be the first to create an interactive guide and share your knowledge with others!
          </p>
          <Link
            to="/guide"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors shadow-lg hover:shadow-xl"
          >
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
            </svg>
            Create First Guide
          </Link>
        </div>
      )}
    </div>
  );
} 