import { Outlet } from "react-router-dom";

interface SimpleLayoutProps {
  children: React.ReactNode;
}

export function SimpleLayout({ children }: SimpleLayoutProps) {
  return (
    <div className="App">
      {children}
    </div>
  );
} 