/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import "./App.scss";
import { Home } from "pages/Home";
import { MainGuide } from "pages/MainGuide";
import { DynamicGuide } from "pages/DynamicGuide";
import { GuideMode } from "pages/GuideMode";
import { ProfilePage } from "pages/ProfilePage";
import { SimpleLayout } from "layouts/SimpleLayout";
import { GuideLayout } from "layouts/GuideLayout";
import { PublicLayout } from "layouts/PublicLayout";
import { AuthProvider } from "contexts/AuthContext";
import { SignIn } from "components/auth/SignIn";
import { SignUp } from "components/auth/SignUp";
import { VerifyEmail } from "components/auth/VerifyEmail";
import { PrivateRoute } from "components/auth/PrivateRoute";
import { ChannelList } from "components/channels/ChannelList";
import { ChannelDetails } from "components/channels/ChannelDetails";
import { DashboardLayout } from 'layouts/DashboardLayout';
import { GuideList } from 'components/GuideList';
import { PublicGuideList } from 'components/PublicGuideList';
import { ChannelManager } from 'components/ChannelManager';
import { LandingPage } from "./components/landing/LandingPage";
import { PricingPage } from "./components/landing/PricingPage";
import { supabase } from "./lib/supabase";

// Auth callback handler component
function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    // Get the hash fragment from the URL
    const hashFragment = window.location.hash;
    
    if (hashFragment) {
      // Parse the hash fragment
      const params = new URLSearchParams(hashFragment.substring(1));
      const accessToken = params.get('access_token');
      const refreshToken = params.get('refresh_token');
      
      if (accessToken && refreshToken) {
        // Set the session in Supabase
        supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken
        }).then(() => {
          // Redirect to learn page after successful auth
          navigate('/learn');
        }).catch((error) => {
          console.error('Error setting session:', error);
          navigate('/signin');
        });
      } else {
        navigate('/signin');
      }
    } else {
      navigate('/signin');
    }
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 mx-auto mb-4"></div>
        <h2 className="text-xl font-semibold text-gray-900">Completing authentication...</h2>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Landing Page as the root route */}
          <Route path="/" element={<LandingPage />} />

          {/* Public routes */}
          <Route element={<SimpleLayout><Outlet /></SimpleLayout>}>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
          </Route>

          {/* Public Guides */}
          <Route element={<PublicLayout><Outlet /></PublicLayout>}>
            <Route path="/learn" element={<PublicGuideList />} />
            <Route path="/guide" element={<GuideMode />} />
            <Route path="/pricing" element={<PricingPage />} />
          </Route>

          {/* Protected routes */}
          <Route element={<PrivateRoute><Outlet /></PrivateRoute>}>
            <Route element={<DashboardLayout><Outlet /></DashboardLayout>}>
              <Route path="/channels/:channelId" element={<GuideList />} />
              <Route path="/channels/manage" element={<ChannelManager />} />
              <Route path="/profile" element={<ProfilePage />} />
            </Route>
          </Route>

          {/* Interactive Guide Experience */}
          <Route element={<GuideLayout><Outlet /></GuideLayout>}>
            <Route path="/guide/:guideId" element={<DynamicGuide />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
