import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { Recording } from '../types/recordings';
import { Guide } from './Guide';
import { Layout } from '../components/layout/Layout';

// Simple Spinner component to replace the missing import
const Spinner = ({ size = 'medium' }: { size?: 'small' | 'medium' | 'large' }) => {
  const sizeClass = {
    small: 'h-6 w-6',
    medium: 'h-8 w-8',
    large: 'h-12 w-12'
  };
  
  return (
    <div className="flex justify-center">
      <div className={`${sizeClass[size]} animate-spin rounded-full border-t-2 border-b-2 border-blue-600`}></div>
    </div>
  );
};

export function DynamicGuide() {
  const { guideId } = useParams<{ guideId: string }>();
  const navigate = useNavigate();
  const [recording, setRecording] = useState<Recording | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPhase, setLoadingPhase] = useState<'initial' | 'fetching' | 'preparing'>('initial');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log("DynamicGuide mounted with guideId:", guideId);
    
    const fetchRecording = async () => {
      setLoadingPhase('fetching');
      console.log("Starting to fetch guide data for ID:", guideId);
      
      if (!guideId) {
        console.error("Guide ID is missing!");
        setError('Guide ID is missing');
        setIsLoading(false);
        return;
      }

      try {
        console.log("Making Supabase request for guide:", guideId);
        const { data, error } = await supabase
          .from('recordings')
          .select('*')
          .eq('id', guideId)
          .single();

        if (error) {
          console.error("Supabase error:", error);
          throw error;
        }

        if (!data) {
          console.error("No guide data found for ID:", guideId);
          throw new Error('Guide not found');
        }

        console.log("Guide data retrieved successfully:", data.title);
        setRecording(data);
        setLoadingPhase('preparing');
        
        // Increment view count
        const { error: updateError } = await supabase
          .from('recordings')
          .update({ view_count: (data.view_count || 0) + 1 })
          .eq('id', guideId);

        if (updateError) {
          console.error("Error updating view count:", updateError);
        }
        
        // Remove artificial delay and set loading to false immediately
        console.log("Loading completed, rendering Guide component");
        setIsLoading(false);
        
      } catch (error: any) {
        console.error('Error fetching guide:', error);
        setError(error.message || 'Failed to load guide');
        setIsLoading(false);
      }
    };

    fetchRecording();
  }, [guideId, navigate]);

  console.log("Current loading state:", { isLoading, loadingPhase, error, hasRecording: !!recording });

  if (isLoading) {
    return (
      <Layout>
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="max-w-md w-full bg-white rounded-xl shadow-md p-8 text-center">
            <Spinner size="large" />
            
            <h2 className="text-xl font-medium text-gray-900 mt-4">
              Preparingexperience
            </h2>
          </div>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
          <div className="max-w-md w-full bg-white rounded-xl shadow-md p-8 text-center">
            <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
              <svg className="h-8 w-8 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            
            <h2 className="text-xl font-medium text-gray-900 mt-4">
              {error.includes('not found') ? 'Guide Not Found' : 'Error Loading Guide'}
            </h2>
            
            <p className="mt-2 text-gray-600">
              {error.includes('not found') 
                ? 'The guide you are looking for does not exist or has been removed.' 
                : 'We encountered an issue loading this guide. Please try again later.'}
            </p>
            
            <button
              onClick={() => navigate('/')}
              className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Return to Home
            </button>
          </div>
        </div>
      </Layout>
    );
  }

  console.log("Rendering Guide component with recording:", recording?.title);
  
  return (
    <Guide
      title={recording?.title || "Untitled Guide"}
      description={recording?.description || "No description available"}
      systemPrompt={recording?.transcript || ""}
      steps={recording?.steps || []}
    />
  );
} 