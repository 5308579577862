import React, { useEffect, useState } from 'react';
import { Layout } from '../components/layout/Layout';
import { useLiveAPIContext } from '../contexts/LiveAPIContext';
import { ShowMeHow } from '../components/show-me-how/ShowMeHow';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

// Flexible step parsing function
const parseStep = (step: string): string => {
  try {
    // Try parsing as JSON if it looks like a JSON object
    if (step.trim().startsWith('{') && step.trim().endsWith('}')) {
      const parsed = JSON.parse(step);
      return parsed.step || parsed.text || parsed.content || parsed.description || step;
    }
    
    // Remove common formatting artifacts
    return step
      .replace(/^["'{]|[}"']$/g, '') // Remove surrounding quotes and braces
      .replace(/^step["\s]*:[\s"]*|^-[\s"]*|^\d+\.[\s"]*|^•[\s"]*/i, '') // Remove step prefixes
      .replace(/\\"/g, '"') // Fix escaped quotes
      .trim();
  } catch {
    // If parsing fails, return cleaned original string
    return step
      .replace(/^["'{]|[}"']$/g, '')
      .replace(/^-\s*/, '')
      .trim();
  }
};

interface GuideProps {
  title?: string;
  description?: string;
  systemPrompt?: string;
  children?: React.ReactNode;
  steps?: string[];
}

interface GuideState {
  showAllSteps: boolean;
  isRecording?: boolean;
  recordingState?: any; // Add any other recording-specific state
}

interface LocationState {
  returnTo?: string;
  guideState?: GuideState;
  message?: string;
}

export function Guide({ 
  title = "Interactive Guide", 
  description = "Learn with interactive AI assistance", 
  systemPrompt = "", 
  children,
  steps = []
}: GuideProps) {
  const { setConfig, connected } = useLiveAPIContext();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showAllSteps, setShowAllSteps] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  // Add any other recording-related state here
  
  // Number of steps to show initially (free preview)
  const PREVIEW_STEPS = 3;

  // Effect to restore state from navigation
  useEffect(() => {
    const state = location.state as LocationState;
    if (state?.guideState) {
      setShowAllSteps(state.guideState.showAllSteps);
      if (state.guideState.isRecording) {
        setIsRecording(state.guideState.isRecording);
      }
      
      // Clear the state after restoring
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  // Effect to handle authentication state changes
  useEffect(() => {
    // If user is authenticated and we have hidden steps, show them
    if (user && !showAllSteps && steps.length > PREVIEW_STEPS) {
      setShowAllSteps(true);
    }
  }, [user, steps.length, showAllSteps]);

  useEffect(() => {
    console.log("Guide component mounted with system prompt:", systemPrompt ? "Present" : "Missing");
    
    if (!systemPrompt) {
      console.warn("System prompt is missing or empty!");
    }

    setConfig({
      model: "models/gemini-2.0-flash-exp",
      systemInstruction: {
        parts: [
          {
            text: systemPrompt || "You are a helpful AI assistant that helps users learn new skills.",
          },
        ],
      },
    });
  }, [setConfig, systemPrompt]);

  // Filter out empty steps and parse them
  const validSteps = steps
    .filter(step => step && step.trim() !== '')
    .map(step => parseStep(step));

  const visibleSteps = showAllSteps ? validSteps : validSteps.slice(0, PREVIEW_STEPS);
  const hasHiddenSteps = validSteps.length > PREVIEW_STEPS;

  const handleExpandClick = () => {
    if (!user) {
      // Save current location and state before redirecting to sign in
      const currentState: GuideState = {
        showAllSteps,
        isRecording
      };

      navigate('/signin', {
        state: { 
          returnTo: location.pathname,
          guideState: currentState,
          message: 'Please sign in to view all steps in this guide'
        }
      });
      return;
    }
    setShowAllSteps(true);
  };

  return (
    <div className="w-full">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Centered Content */}
        <div className="text-center">
          <div className="relative inline-block">
            <div className="absolute inset-0 bg-blue-500/10 blur-3xl rounded-lg sm:rounded-full transform -translate-x-1/2 translate-y-1/4"></div>
            <h1 className="relative mb-4">
              <span className="block text-[2rem] sm:text-[3rem] font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400 tracking-tight leading-none">
                {title}
              </span>
              <span className="block text-lg sm:text-2xl mt-6 text-gray-600 font-normal tracking-tight">
                {description}
              </span>
            </h1>
          </div>

          {/* Enhanced Show Me How Button with Animation and Glow */}
          <div className="mt-12 relative w-full sm:w-auto">
            {/* Animated glow effect behind button */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-full sm:w-32 h-14 sm:h-32 bg-blue-400/30 rounded-lg sm:rounded-full blur-xl animate-pulse-once"></div>
            </div>

            {/* Button itself */}
            <div className="relative w-full sm:w-auto">
              <ShowMeHow />
              <p className="mt-4 text-blue-700 font-medium text-lg">
                Click to start interactive guide
              </p>
            </div>
          </div>

          {/* Steps Section */}
          {visibleSteps.length > 0 && (
            <div className="mt-16 text-left bg-white rounded-xl shadow-sm border border-gray-100 p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Guide Steps</h2>
              <ol className="space-y-4">
                {visibleSteps.map((step, index) => (
                  <li key={index} className="flex items-start gap-4 group">
                    <div className="flex-shrink-0 w-8 h-8 bg-blue-50 text-blue-600 rounded-full flex items-center justify-center font-medium group-hover:bg-blue-100 group-hover:text-blue-700 transition-colors">
                      {index + 1}
                    </div>
                    <div className="flex-grow">
                      <p className="text-gray-700 leading-relaxed pt-0.5 group-hover:text-gray-900 transition-colors">
                        {step}
                      </p>
                    </div>
                  </li>
                ))}
              </ol>

              {/* Show More Button */}
              {hasHiddenSteps && !showAllSteps && (
                <div className="mt-8 text-center">
                  <button
                    onClick={handleExpandClick}
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:scale-105"
                  >
                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                    Show More Steps
                  </button>
                  <p className="mt-2 text-sm text-gray-500">
                    Sign in to access all {validSteps.length} steps
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Progress Indicator */}
        {connected && (
          <div className="mt-12 flex justify-center">
            <div className="inline-flex items-center px-5 py-3 bg-blue-50 rounded-lg sm:rounded-full text-sm text-blue-600 font-medium tracking-wide">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Guide in Progress
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 