import { useRef, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { LiveAPIProvider } from "../contexts/LiveAPIContext";
import SidePanel from "../components/side-panel/SidePanel";
import ControlTray from "../components/control-tray/ControlTray";
import { Header } from "../components/landing/Header";
import cn from "classnames";

interface GuideLayoutProps {
  children: React.ReactNode;
}

const API_KEY = process.env.REACT_APP_GEMINI_API_KEY as string;
const host = "generativelanguage.googleapis.com";
const uri = `wss://${host}/ws/google.ai.generativelanguage.v1alpha.GenerativeService.BidiGenerateContent`;

export function GuideLayout({ children }: GuideLayoutProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [apiKeyWarning, setApiKeyWarning] = useState<string | null>(null);
  const apiKey = process.env.REACT_APP_GEMINI_API_KEY;

  useEffect(() => {
    // Check if API key is available and valid
    if (!apiKey) {
      console.error('Required API key is missing. Please check your environment configuration.');
      setApiKeyWarning('Missing Gemini API key. Guides will not function correctly.');
    } else if (apiKey === 'YOUR_GEMINI_API_KEY_HERE') {
      console.error('API key is still set to the placeholder value.');
      setApiKeyWarning('Placeholder API key detected. Please replace with your actual Gemini API key.');
    } else {
      console.log('API configuration verified');
      setApiKeyWarning(null);
    }
  }, [apiKey]);

  return (
    <LiveAPIProvider url={uri} apiKey={API_KEY}>
      <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-50 via-white to-blue-50">
        <Header />
        {apiKeyWarning && (
          <div className="bg-yellow-50 border-b border-yellow-100 p-3">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-center items-center space-x-2">
                <svg className="h-5 w-5 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <span className="text-sm font-medium text-yellow-700">{apiKeyWarning}</span>
                <a 
                  href="https://aistudio.google.com/app/apikey" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-sm font-medium text-blue-600 underline"
                >
                  Get API Key
                </a>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-1 relative">
          <main className="flex-1 w-full transition-all duration-200 ease-in-out">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {children || <Outlet />}
            </div>
          </main>
          <div className="absolute left-0 top-0 h-full">
            <SidePanel />
          </div>
        </div>
      </div>
    </LiveAPIProvider>
  );
} 