import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';

interface Channel {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

interface ChannelSidebarProps {
  isOpen: boolean;
}

export function ChannelSidebar({ isOpen }: ChannelSidebarProps) {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchUserChannels();
    } else {
      setLoading(false);
    }
  }, [user]);

  async function fetchUserChannels() {
    try {
      setLoading(true);
      const { data: channelMembers, error: memberError } = await supabase
        .from('channel_members')
        .select('channel_id')
        .eq('user_id', user?.id);

      if (memberError) throw memberError;

      const channelIds = channelMembers?.map(member => member.channel_id) || [];

      if (channelIds.length > 0) {
        const { data: channelsData, error: channelsError } = await supabase
          .from('channels')
          .select('*')
          .in('id', channelIds)
          .order('name');

        if (channelsError) throw channelsError;
        setChannels(channelsData || []);
      }
    } catch (error) {
      console.error('Error fetching channels:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="px-3 py-2">
        {/* Public Section */}
        <div className={`mb-4 ${!isOpen && 'text-center'}`}>
          <div className="px-2 py-1 text-xs font-semibold text-gray-500 uppercase tracking-wider">
            {isOpen ? 'Public' : 'P'}
          </div>
          <Link
            to="/learn"
            className={`
              mt-1 group flex items-center px-2 py-2 text-sm
              font-medium rounded-md hover:bg-gray-200
              ${location.pathname === '/learn' ? 'bg-gray-200 text-gray-900' : 'text-gray-700'}
            `}
          >
            <svg className="mr-3 h-5 w-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            {isOpen && <span>Public Guides</span>}
          </Link>
        </div>

        {/* Channels Section - Only show for authenticated users */}
        {user && (
          <div className={!isOpen ? 'text-center' : undefined}>
            <div className="px-2 py-1 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              {isOpen ? 'My Channels' : 'C'}
            </div>
            
            {loading ? (
              <div className="px-2 py-2 text-sm text-gray-500">Loading...</div>
            ) : (
              <>
                {channels.map((channel) => (
                  <Link
                    key={channel.id}
                    to={`/channels/${channel.id}`}
                    className={`
                      mt-1 group flex items-center px-2 py-2 text-sm
                      font-medium rounded-md hover:bg-gray-200
                      ${location.pathname === `/channels/${channel.id}` ? 'bg-gray-200 text-gray-900' : 'text-gray-700'}
                    `}
                  >
                    <svg className="mr-3 h-5 w-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
                    </svg>
                    {isOpen && (
                      <span className="truncate" title={channel.name}>
                        {channel.name}
                      </span>
                    )}
                  </Link>
                ))}

                <Link
                  to="/channels/manage"
                  className="mt-4 group flex items-center px-2 py-2 text-sm font-medium text-gray-700 rounded-md hover:bg-gray-200"
                >
                  <svg className="mr-3 h-5 w-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  {isOpen && <span>Manage Channels</span>}
                </Link>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
} 