import { createClient } from '@supabase/supabase-js';

// Check if environment variables are defined
if (!process.env.REACT_APP_SUPABASE_URL) {
  throw new Error('Missing environment variable: REACT_APP_SUPABASE_URL');
}
if (!process.env.REACT_APP_SUPABASE_ANON_KEY) {
  throw new Error('Missing environment variable: REACT_APP_SUPABASE_ANON_KEY');
}

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL as string;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY as string;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Let me see how Supabase is initialized and which environment variables it's using 