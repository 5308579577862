import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { Link, Navigate } from 'react-router-dom';

interface UserProfile {
  id: string;
  display_name?: string;
  avatar_url?: string;
  email: string;
  created_at: string;
  updated_at?: string;
}

export function ProfilePage() {
  const { user, signOut } = useAuth();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Define fetchProfile outside useEffect so it can be reused
  const fetchProfile = async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', user.id)
        .single();
      
      if (error) {
        console.error('Error fetching profile:', error);
        return;
      }
      
      if (data) {
        setProfile(data);
        setDisplayName(data.display_name || '');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Move useEffect before conditional return
  useEffect(() => {
    if (user) {
      fetchProfile();
    }
  }, [user]);

  // Redirect if not logged in
  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  // Handle profile form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!user) return;
    
    try {
      setSaving(true);
      setSuccessMessage('');
      setErrorMessage('');
      
      const updates = {
        id: user.id,
        display_name: displayName,
        updated_at: new Date().toISOString(),
      };
      
      const { error } = await supabase
        .from('users')
        .upsert([updates]);
      
      if (error) {
        setErrorMessage('Error updating profile. Please try again.');
        console.error('Error updating profile:', error);
      } else {
        setSuccessMessage('Profile updated successfully!');
        
        // Refresh profile data
        await fetchProfile();
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setSaving(false);
      
      // Clear success message after 3 seconds
      if (successMessage) {
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    }
  };

  // Handle sign out
  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Calculate account age
  const getAccountAge = () => {
    if (!profile?.created_at) return 'New account';
    
    const createdDate = new Date(profile.created_at);
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - createdDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays < 1) return 'Created today';
    if (diffDays === 1) return 'Created yesterday';
    if (diffDays < 30) return `Created ${diffDays} days ago`;
    if (diffDays < 365) {
      const months = Math.floor(diffDays / 30);
      return `Created ${months} ${months === 1 ? 'month' : 'months'} ago`;
    }
    
    const years = Math.floor(diffDays / 365);
    return `Created ${years} ${years === 1 ? 'year' : 'years'} ago`;
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
      {/* Back Navigation */}
      <div className="mb-6">
        <Link 
          to="/learn" 
          className="flex items-center text-blue-600 font-medium transition-all duration-300 hover:text-blue-700"
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          Back to Guides
        </Link>
      </div>

      {/* Page Heading */}
      <h1 className="text-2xl font-medium tracking-tight text-gray-900 mb-8">
        Your Profile
      </h1>

      {/* Profile Card */}
      <div className="relative group transition-all duration-300 hover:scale-[1.01]">
        {/* Decorative Elements */}
        <div className="absolute -top-10 -right-10 w-40 h-40 bg-blue-100 rounded-full filter blur-3xl opacity-20 group-hover:opacity-30 transition-opacity duration-300"></div>
        <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-blue-200 rounded-full filter blur-3xl opacity-20 group-hover:opacity-30 transition-opacity duration-300"></div>
        
        <div className="relative bg-white border border-gray-100 rounded-2xl p-6 shadow-lg overflow-hidden">
          {/* Messages */}
          {errorMessage && (
            <div className="mb-6 p-4 bg-red-50 border border-red-100 rounded-xl">
              <p className="text-sm text-red-600">{errorMessage}</p>
            </div>
          )}
          
          {successMessage && (
            <div className="mb-6 p-4 bg-blue-50 border border-blue-100 rounded-xl">
              <p className="text-sm text-blue-600">{successMessage}</p>
            </div>
          )}

          {loading ? (
            <div className="py-12 flex justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <div className="space-y-8">
              {/* User Info Section */}
              <div className="flex items-center space-x-5">
                <div className="h-20 w-20 rounded-2xl bg-gradient-to-br from-blue-500 to-blue-600 text-white flex items-center justify-center text-3xl font-medium transform transition-all duration-300 hover:rotate-3">
                  {profile?.display_name?.charAt(0).toUpperCase() || user.email?.charAt(0).toUpperCase() || 'U'}
                </div>
                <div>
                  <h2 className="text-xl font-medium tracking-tight text-gray-900 group-hover:text-blue-600 transition-colors duration-300">
                    {profile?.display_name || user.email?.split('@')[0] || 'User'}
                  </h2>
                  <p className="text-gray-600 font-light">{user.email}</p>
                  <p className="text-sm text-blue-500 mt-1">Member for {getAccountAge()}</p>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-8">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-6">Profile Information</h3>
                  
                  <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2">
                    <div className="transition-all duration-300 focus-within:scale-[1.02]">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        Email Address
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={user.email || ''}
                          disabled
                          className="w-full px-4 py-3 bg-gray-50 border border-gray-100 rounded-xl text-gray-500 font-light focus:ring-blue-500 focus:border-blue-500"
                        />
                        <p className="mt-1 text-xs text-gray-500">Email cannot be changed</p>
                      </div>
                    </div>

                    <div className="sm:col-span-2 transition-all duration-300 focus-within:scale-[1.02]">
                      <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 mb-1">
                        Display Name
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          name="display_name"
                          id="display_name"
                          value={displayName}
                          onChange={(e) => setDisplayName(e.target.value)}
                          className="w-full px-4 py-3 bg-white border border-gray-100 rounded-xl text-gray-900 font-light focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Enter your display name"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center pt-6 border-t border-gray-100">
                  <button
                    type="submit"
                    disabled={saving}
                    className="inline-flex justify-center px-8 py-3 bg-blue-600 text-white font-medium rounded-xl shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 hover:shadow-xl disabled:opacity-70"
                  >
                    {saving ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Saving...
                      </>
                    ) : (
                      'Save Changes'
                    )}
                  </button>
                  
                  <button
                    type="button"
                    onClick={handleSignOut}
                    className="inline-flex items-center px-6 py-3 bg-blue-50 text-blue-600 font-medium rounded-xl hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    Sign Out
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
} 