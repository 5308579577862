import React from "react";
import { Header } from "./Header";
import { Hero } from "./Hero";
import { ShowcaseSection } from "./ShowcaseSection";
import { Link } from "react-router-dom";
import { Button } from "../ui/Button";

export function LandingPage() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-1 pt-16">
        <Hero />
        <ShowcaseSection />
        
        {/* Call to Action Section */}
        <section className="w-full py-12 md:py-24 lg:py-32 bg-primary text-primary-foreground">
          <div className="max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                  Ready to Share Your Knowledge?
                </h2>
                <p className="mx-auto max-w-[700px] text-primary-foreground/80 md:text-xl">
                  Create interactive guides to preserve your team's expertise or start learning from others' experiences.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                <Link to="/guide">
                  <Button className="w-full sm:w-auto bg-white text-primary hover:bg-white/90">
                    Create a Guide
                  </Button>
                </Link>
                <Link to="/learn">
                  <Button variant="outline" className="w-full sm:w-auto border-primary-foreground text-primary-foreground hover:bg-primary-foreground/10">
                    Use Guides
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      
      <footer className="w-full py-6 bg-background border-t">
        <div className="max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-center gap-4 sm:gap-6">
            <Link to="/learn" className="text-sm hover:underline">
              Show Me How
            </Link>
            <Link to="/guide" className="text-sm hover:underline">
              Show Them How
            </Link>
            <Link to="/pricing" className="text-sm hover:underline">
              Pricing
            </Link>
            <a 
              href="https://cal.com/andrewsiah+irismeng/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-sm hover:underline"
            >
              Contact
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
} 