import React from 'react';
import { useShowMeHow } from './useShowMeHow';
import AudioPulse from '../audio-pulse/AudioPulse';

export const ShowMeHow: React.FC = () => {
  const {
    state,
    startSession,
    endSession,
    error,
    videoRef,
    renderCanvasRef,
    toggleVideoSource,
    toggleMute,
    muted,
    inVolume,
    currentVideoSource,
  } = useShowMeHow();

  const handleClick = async () => {
    if (state === 'active') {
      await endSession();
    } else {
      await startSession();
    }
  };

  const getButtonStyle = () => {
    const baseStyle = "w-full sm:w-auto px-6 py-3 rounded-lg font-medium text-base sm:text-lg transition-all duration-200 flex items-center justify-center gap-2 shadow-md hover:shadow-lg";
    
    switch (state) {
      case 'idle':
        return `${baseStyle} bg-blue-600 text-white hover:bg-blue-700`;
      case 'connecting':
        return `${baseStyle} bg-blue-100 text-blue-600 cursor-wait`;
      case 'active':
        return `${baseStyle} bg-red-600 text-white hover:bg-red-700`;
      case 'error':
        return `${baseStyle} bg-red-100 text-red-600 hover:bg-red-200`;
    }
  };

  const getButtonContent = () => {
    switch (state) {
      case 'idle':
        return (
          <>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Show Me How
          </>
        );
      case 'connecting':
        return (
          <>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Connecting...
          </>
        );
      case 'active':
        return (
          <>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z" />
            </svg>
            End Session
          </>
        );
      case 'error':
        return (
          <>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Retry
          </>
        );
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 w-full sm:w-auto">
      {/* Hidden video element for stream display */}
      <video
        ref={videoRef}
        style={{ display: 'none' }}
        autoPlay
        playsInline
        muted
      />
      <canvas ref={renderCanvasRef} style={{ display: 'none' }} />

      {/* Main button */}
      <div className="relative w-full sm:w-auto">
        <button
          onClick={handleClick}
          className={getButtonStyle()}
          disabled={state === 'connecting'}
          title={error?.message}
        >
          {getButtonContent()}
        </button>
        {error && (
          <div className="absolute top-full mt-2 p-2 bg-red-50 border border-red-200 rounded-md text-sm text-red-600">
            {error.message}
          </div>
        )}
      </div>

      {/* Media controls */}
      {state === 'active' && (
        <div className="flex items-center gap-3 mt-2">
          {/* Mic toggle */}
          <button
            className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
            onClick={toggleMute}
            title={muted ? 'Unmute' : 'Mute'}
          >
            <span className="material-symbols-outlined filled">
              {muted ? 'mic_off' : 'mic'}
            </span>
          </button>

          {/* Audio pulse */}
          <div className="w-8 h-8 flex items-center justify-center">
            <AudioPulse volume={inVolume} active={true} hover={false} />
          </div>

          {/* Video source toggle */}
          <button
            className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
            onClick={toggleVideoSource}
            title={currentVideoSource === 'webcam' ? 'Switch to screen share' : 'Switch to webcam'}
          >
            <span className="material-symbols-outlined">
              {currentVideoSource === 'webcam' ? 'present_to_all' : 'videocam'}
            </span>
          </button>
        </div>
      )}
    </div>
  );
}; 