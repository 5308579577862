import { supabase } from './supabase';
import type { Channel, ChannelMember, ChannelRole } from '../types/database';

export async function createChannel(name: string, description?: string): Promise<Channel | null> {
  const { data, error } = await supabase
    .from('channels')
    .insert([{ name, description }])
    .select()
    .single();
  
  if (error) throw error;
  return data;
}

export async function getMyChannels(): Promise<Channel[]> {
  const { data, error } = await supabase
    .from('channels')
    .select(`
      *,
      channel_members!inner (
        role
      )
    `)
    .order('created_at', { ascending: false });
  
  if (error) throw error;
  return data || [];
}

export async function getChannelMembers(channelId: string): Promise<ChannelMember[]> {
  const { data, error } = await supabase
    .from('channel_members')
    .select(`
      *,
      users (
        id,
        display_name,
        avatar_url
      )
    `)
    .eq('channel_id', channelId);
  
  if (error) throw error;
  return data || [];
}

export async function addChannelMember(
  channelId: string, 
  userId: string, 
  role: ChannelRole = 'member'
): Promise<void> {
  const { error } = await supabase
    .from('channel_members')
    .insert([{ channel_id: channelId, user_id: userId, role }]);
  
  if (error) throw error;
}

export async function updateChannelMemberRole(
  channelId: string,
  userId: string,
  newRole: ChannelRole
): Promise<void> {
  const { error } = await supabase
    .from('channel_members')
    .update({ role: newRole })
    .eq('channel_id', channelId)
    .eq('user_id', userId);
  
  if (error) throw error;
}

export async function removeChannelMember(channelId: string, userId: string): Promise<void> {
  const { error } = await supabase
    .from('channel_members')
    .delete()
    .eq('channel_id', channelId)
    .eq('user_id', userId);
  
  if (error) throw error;
}

export async function updateChannel(
  channelId: string,
  updates: { name?: string; description?: string }
): Promise<Channel | null> {
  const { data, error } = await supabase
    .from('channels')
    .update(updates)
    .eq('id', channelId)
    .select()
    .single();
  
  if (error) throw error;
  return data;
}

export async function deleteChannel(channelId: string): Promise<void> {
  const { error } = await supabase
    .from('channels')
    .delete()
    .eq('id', channelId);
  
  if (error) throw error;
} 