import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Layout } from '../components/layout/Layout';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { analyzeVideo, generateMetadata } from '../services/videoAnalysisService';
import { ChannelSelector } from '../components/channels/ChannelSelector';
import { useAuth } from '../contexts/AuthContext';
import { RecordingType } from '../types/recordings';
import { calculateVideoDuration, checkWebMForAudioTracks, formatDuration } from '../lib/utils';

const loadingMessages = [
  "Analyzing your video...",
  "Extracting knowledge...",
  "Processing content...",
  "Almost there...",
  "Generating guide...",
  "Making it interactive..."
];

const PUBLIC_ORG_ID = '00000000-0000-0000-0000-000000000000';

export function GuideMode() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [isPublic, setIsPublic] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [recordingCompleted, setRecordingCompleted] = useState(false);
  const [recordingData, setRecordingData] = useState<any>(null);
  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [recordingStats, setRecordingStats] = useState<{
    duration: number;
    size: string;
    timestamp: string;
    hasAudio?: boolean;
  } | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const estimatedDurationRef = useRef<number>(0);

  // Check for pending recording after authentication
  useEffect(() => {
    if (user && sessionStorage.getItem('pendingRecording') === 'true') {
      const blobUrl = sessionStorage.getItem('recordingBlobUrl');
      const statsJson = sessionStorage.getItem('recordingStats');
      
      if (blobUrl) {
        // Fetch the blob from the URL
        fetch(blobUrl)
          .then(response => response.blob())
          .then(blob => {
            setRecordingBlob(blob);
            // Create a new preview URL for the restored blob
            const newPreviewUrl = URL.createObjectURL(blob);
            setPreviewUrl(newPreviewUrl);
            
            if (statsJson) {
              setRecordingStats(JSON.parse(statsJson));
            }
            setShowConfirmation(true);
            
            // Clean up session storage
            sessionStorage.removeItem('pendingRecording');
            sessionStorage.removeItem('recordingBlobUrl');
            sessionStorage.removeItem('recordingStats');
            URL.revokeObjectURL(blobUrl); // Clean up the storage blob URL
            
            // Show a message about being able to process the recording now
            setError("You're now signed in! You can now process your recording.");
            
            // Clear the error message after 5 seconds
            const timer = setTimeout(() => {
              setError(null);
            }, 5000);
            
            return () => {
              clearTimeout(timer);
              // Clean up the preview URL when component unmounts
              if (newPreviewUrl) {
                URL.revokeObjectURL(newPreviewUrl);
              }
            };
          })
          .catch(error => {
            console.error('Failed to restore recording:', error);
            setError('Failed to restore your recording. Please try recording again.');
          });
      }
    }
  }, [user]);

  // Rotate through loading messages
  useEffect(() => {
    if (!isProcessing) return;

    const interval = setInterval(() => {
      setLoadingMessageIndex((prev) => (prev + 1) % loadingMessages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [isProcessing]);

  // Function to get video duration and create thumbnail
  const getVideoMetadata = useCallback(async (blob: Blob) => {
    // Create an object URL for the video blob
    const url = URL.createObjectURL(blob);
    setPreviewUrl(url);
    
    // Function to format file size (moved inside useCallback)
    const formatFileSize = (bytes: number): string => {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };
    
    try {
      // Calculate video duration using the utility function
      let duration = 60; // Default duration in case calculation fails
      
      try {
        duration = await calculateVideoDuration(blob);
        
        // Validate the duration
        if (!isFinite(duration) || isNaN(duration) || duration <= 0) {
          // Use estimated duration if available, otherwise default to 60 seconds
          if (estimatedDurationRef.current > 0) {
            duration = estimatedDurationRef.current;
          } else {
            duration = 60;
          }
        }
      } catch (durationError) {
        // Use estimated duration if available, otherwise default to 60 seconds
        if (estimatedDurationRef.current > 0) {
          duration = estimatedDurationRef.current;
        }
      }
      
      // Check for audio using simplified detection
      const hasAudio = await checkForAudio(blob);
      
      // Get file size
      const fileSizeFormatted = formatFileSize(blob.size);
      
      // Get current timestamp
      const now = new Date();
      const timestamp = now.toLocaleString();
      
      // Set recording stats
      setRecordingStats({
        duration: duration,
        size: fileSizeFormatted,
        timestamp: timestamp,
        hasAudio
      });
      
      // Clear any previous errors - we'll assume audio is working even if detection is uncertain
      setError(null);
    } catch (error) {
      // Fallback to default duration
      const fileSizeFormatted = formatFileSize(blob.size);
      const now = new Date();
      const timestamp = now.toLocaleString();
      
      setRecordingStats({
        duration: 60, // Default to 60 seconds
        size: fileSizeFormatted,
        timestamp: timestamp,
        hasAudio: true // Assume audio is present by default
      });
    }
  }, [setPreviewUrl, setRecordingStats, setError]);

  // Helper function to check for audio in the recording
  const checkForAudio = async (blob: Blob): Promise<boolean> => {
    try {
      // First try to check WebM file for audio tracks
      if (blob.type.includes('webm')) {
        const hasAudioTracks = await checkWebMForAudioTracks(blob);
        if (hasAudioTracks !== null) {
          return hasAudioTracks;
        }
      }
      
      // Fall back to audio context analysis
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      
      // Get audio data from blob
      const arrayBuffer = await blob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      
      // Get audio data
      const channelData = audioBuffer.getChannelData(0);
      
      // Check if there's any significant audio data
      // Calculate RMS (root mean square) of audio samples
      let sum = 0;
      const sampleSize = Math.min(channelData.length, 10000); // Sample first 10000 samples or less
      
      for (let i = 0; i < sampleSize; i++) {
        sum += channelData[i] * channelData[i];
      }
      
      const rms = Math.sqrt(sum / sampleSize);
      const hasAudio = rms > 0.001; // Threshold for considering audio present
      
      return hasAudio;
    } catch (error) {
      // Fallback to simple size-based detection
      return blob.size > 10000;
    }
  };

  const startRecording = useCallback(async () => {
    try {
      // Clear any previous errors and recording state
      setError(null);
      setRecordingCompleted(false);
      setRecordingData(null);
      setRecordingBlob(null);
      setPreviewUrl(null);
      setShowConfirmation(false);

      // Request screen capture with system audio
      const displayStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: {
          // Request system audio capture
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
        }
      }).catch((err) => {
        throw new Error('Screen sharing permission denied');
      });
      
      // Check if we have audio tracks from display capture
      const hasDisplayAudio = displayStream.getAudioTracks().length > 0;
      
      let finalStream = displayStream;
      
      // If no system audio was captured, try to get microphone audio
      if (!hasDisplayAudio) {
        try {
          // Request microphone access
          const micStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
              sampleRate: 44100,
            }
          });
          
          // Add microphone audio tracks to the stream
          micStream.getAudioTracks().forEach(track => {
            finalStream.addTrack(track);
          });
        } catch (micError) {
          // Continue with just screen capture if mic access fails
        }
      }
      
      // Create MediaRecorder with the combined stream
      const recorder = new MediaRecorder(finalStream, {
        mimeType: 'video/webm;codecs=vp9,opus'
      });
      
      const chunks: BlobPart[] = [];
      
      // Store start time for duration estimation
      const startTime = Date.now();
      estimatedDurationRef.current = 0;
      
      recorder.ondataavailable = e => chunks.push(e.data);
      
      recorder.onstop = async () => {
        // Calculate estimated duration
        const endTime = Date.now();
        const durationMs = endTime - startTime;
        estimatedDurationRef.current = durationMs / 1000;
        
        const blob = new Blob(chunks, { type: "video/webm" });
        setRecordingBlob(blob);
        
        // Instead of immediately processing, show confirmation screen with metadata
        getVideoMetadata(blob);
        setShowConfirmation(true);
        setIsRecording(false);
      };
      
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
      
      // Stop recording when screen sharing is stopped
      displayStream.getVideoTracks()[0].onended = () => {
        recorder.stop();
        // Ensure all tracks are stopped
        finalStream.getTracks().forEach(track => track.stop());
      };
    } catch (error) {
      setIsRecording(false);
      setError(error instanceof Error ? error.message : 'Failed to start recording');
    }
  }, [navigate, user, selectedChannels, isPublic, getVideoMetadata]);

  const stopRecording = useCallback(() => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
  }, [mediaRecorder]);

  const handleKeepGuide = useCallback(() => {
    // Guide is already saved in the database, just navigate to it
    if (recordingData && recordingData.id) {
      navigate(`/guide/${recordingData.id}`);
    }
  }, [recordingData, navigate]);

  const handleRestartRecording = useCallback(() => {
    // If we have existing recording data, delete it first
    const deleteExistingRecording = async () => {
      if (recordingData && recordingData.id) {
        try {
          // Delete from database
          await supabase
            .from('recordings')
            .delete()
            .eq('id', recordingData.id);
          
          // If there's a URL, extract filename from it to delete from storage
          if (recordingData.video_url) {
            const urlParts = recordingData.video_url.split('/');
            const fileName = urlParts[urlParts.length - 1];
            
            await supabase.storage
              .from('recordings')
              .remove([fileName]);
          }
        } catch (error) {
          console.error("Error deleting recording:", error);
        }
      }
    };

    // Delete and restart
    deleteExistingRecording().then(() => {
      setRecordingData(null);
      setRecordingCompleted(false);
      setRecordingBlob(null);
      startRecording();
    });
  }, [recordingData, startRecording]);

  // Function to proceed with processing after confirmation
  const handleProcessRecording = async () => {
    if (!recordingBlob) return;
    
    // Check if user is authenticated before proceeding
    if (!user) {
      // Create a URL for the recording blob that we can pass through navigation
      const blobUrl = URL.createObjectURL(recordingBlob);
      
      // Save recording state in sessionStorage
      sessionStorage.setItem('pendingRecording', 'true');
      sessionStorage.setItem('recordingBlobUrl', blobUrl);
      if (recordingStats) {
        sessionStorage.setItem('recordingStats', JSON.stringify(recordingStats));
      }
      
      // Redirect to sign-in page with return state
      navigate('/signin', { 
        state: { 
          message: "Please sign in to save and process your recording",
          returnTo: '/guide',
          recordingPending: true
        } 
      });
      return;
    }
    
    setShowConfirmation(false);
    setIsProcessing(true);
    
    try {
      // Upload video to Supabase Storage
      const timestamp = new Date().getTime();
      const fileName = `recording-${timestamp}.webm`;
      
      const { data: storageData, error: storageError } = await supabase.storage
        .from('recordings')
        .upload(fileName, recordingBlob);

      if (storageError) {
        throw new Error("Failed to upload video");
      }

      // Get public URL for the video
      const { data: { publicUrl } } = supabase.storage
        .from('recordings')
        .getPublicUrl(fileName);

      // Analyze video content
      const transcript = await analyzeVideo(recordingBlob);
      
      // Generate metadata
      const metadata = await generateMetadata(transcript);

      // Save recording metadata to database
      const { data: recordingData, error: recordingError } = await supabase
        .from('recordings')
        .insert([
          {
            title: metadata.title,
            description: metadata.description,
            video_url: publicUrl,
            transcript: transcript,
            creator_id: user?.id,
            organization_id: isPublic ? PUBLIC_ORG_ID : user?.id,
            recording_type: 'CREATION', // Set type for new recordings
            parent_recording_id: null, // No parent for original recordings
            duration_seconds: recordingStats?.duration || null, // Use calculated duration
            steps: metadata.steps // Add the steps array
          }
        ])
        .select()
        .single();

      if (recordingError) {
        throw new Error("Failed to save recording metadata");
      }

      // If not public and channels are selected, create recording permissions
      if (!isPublic && selectedChannels.length > 0 && recordingData) {
        const permissionPromises = selectedChannels.map(channelId =>
          supabase
            .from('recording_permissions')
            .insert([{
              recording_id: recordingData.id,
              channel_id: channelId
            }])
        );

        await Promise.all(permissionPromises);
      }

      // Set recording as completed and store data for post-recording options
      setRecordingData(recordingData);
      setRecordingCompleted(true);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to process recording');
    } finally {
      setIsProcessing(false);
    }
  };

  // Update existing handleDropGuide to handle pre-processing case
  const handleDropGuide = useCallback(() => {
    // If we're in pre-processing confirmation, just clean up and return to initial state
    if (showConfirmation) {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
      setRecordingBlob(null);
      setShowConfirmation(false);
      setRecordingStats(null);
      setPreviewUrl(null);
      return;
    }

    // Delete the recording and return to the learning page
    const deleteRecording = async () => {
      if (recordingData && recordingData.id) {
        try {
          // Delete from database
          await supabase
            .from('recordings')
            .delete()
            .eq('id', recordingData.id);
          
          // If there's a URL, extract filename from it to delete from storage
          if (recordingData.video_url) {
            const urlParts = recordingData.video_url.split('/');
            const fileName = urlParts[urlParts.length - 1];
            
            await supabase.storage
              .from('recordings')
              .remove([fileName]);
          }
          
          // Return to learn page
          navigate('/learn');
        } catch (error) {
          console.error("Error deleting recording:", error);
          setError("Failed to delete recording. Please try again.");
        }
      } else {
        // If no recording data, just navigate back
        navigate('/learn');
      }
    };

    deleteRecording();
  }, [recordingData, navigate, showConfirmation, previewUrl]);

  // Format duration as MM:SS
  const formatDuration = (seconds: number): string => {
    // Ensure seconds is a valid number
    if (isNaN(seconds) || !isFinite(seconds)) {
      seconds = 60; // Default to 60 seconds if invalid
    }
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Layout>
      <div className="min-h-screen bg-gradient-to-b from-gray-50 via-white to-blue-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center">
            {error && (
              <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md">
                <p className="text-sm text-red-600">{error}</p>
              </div>
            )}
            <div className="mb-8">
              <Link
                to="/learn"
                className="inline-flex items-center text-blue-600 hover:text-blue-800"
              >
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
                Back to Guides
              </Link>
            </div>

            <div className="max-w-2xl mx-auto">
              <div className="bg-white rounded-2xl shadow-xl p-8">
                {isProcessing ? (
                  <div className="text-center py-12">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 mx-auto mb-4"></div>
                    <h2 className="text-2xl font-medium text-gray-900 mb-2">
                      {loadingMessages[loadingMessageIndex]}
                    </h2>
                    <p className="text-gray-500">This may take a few moments...</p>
                  </div>
                ) : showConfirmation ? (
                  // Pre-processing confirmation UI
                  <div className="text-center">
                    <h2 className="text-2xl font-medium text-gray-900 mb-4">
                      Review Your Recording
                    </h2>
                    <p className="text-gray-600 mb-6">
                      Your recording is ready to be processed. What would you like to do?
                    </p>

                    {/* Video Preview */}
                    <div className="mb-6 rounded-lg overflow-hidden border border-gray-200 bg-gray-50">
                      {previewUrl && (
                        <video 
                          ref={videoRef}
                          src={previewUrl} 
                          className="w-full max-h-64 object-contain"
                          controls
                        ></video>
                      )}
                    </div>

                    {/* Recording Stats */}
                    {recordingStats && (
                      <div className="grid grid-cols-3 gap-4 mb-8">
                        <div className="bg-blue-50 rounded-lg p-3">
                          <div className="flex items-center justify-center text-blue-600 mb-2">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                          <p className="text-xs text-gray-500">Duration</p>
                          <p className="text-base font-medium text-gray-700">{formatDuration(recordingStats.duration)}</p>
                        </div>
                        
                        <div className="bg-blue-50 rounded-lg p-3">
                          <div className="flex items-center justify-center text-blue-600 mb-2">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 7v10c0 2 1 3 3 3h10c2 0 3-1 3-3V7c0-2-1-3-3-3H7c-2 0-3 1-3 3z" />
                            </svg>
                          </div>
                          <p className="text-xs text-gray-500">File Size</p>
                          <p className="text-base font-medium text-gray-700">{recordingStats.size}</p>
                        </div>
                        
                        <div className="bg-blue-50 rounded-lg p-3">
                          <div className="flex items-center justify-center text-blue-600 mb-2">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                          </div>
                          <p className="text-xs text-gray-500">Recorded</p>
                          <p className="text-base font-medium text-gray-700">{recordingStats.timestamp.split(',')[0]}</p>
                        </div>
                      </div>
                    )}

                    {/* Audio Status Indicator */}
                    {recordingStats && (
                      <div className={`mb-6 p-3 rounded-lg ${recordingStats.hasAudio ? 'bg-green-50' : 'bg-yellow-50'}`}>
                        <div className="flex items-center">
                          <div className={`flex-shrink-0 ${recordingStats.hasAudio ? 'text-green-500' : 'text-yellow-500'}`}>
                            {recordingStats.hasAudio ? (
                              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                              </svg>
                            ) : (
                              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                              </svg>
                            )}
                          </div>
                          <div className="ml-3">
                            <h3 className={`text-sm font-medium ${recordingStats.hasAudio ? 'text-green-800' : 'text-yellow-800'}`}>
                              {recordingStats.hasAudio ? 'Audio Detected' : 'No Audio Detected'}
                            </h3>
                            <div className={`mt-1 text-xs ${recordingStats.hasAudio ? 'text-green-700' : 'text-yellow-700'}`}>
                              {recordingStats.hasAudio 
                                ? 'Your recording includes audio which will enhance the guide experience.' 
                                : 'Your recording appears to be silent. Consider re-recording with audio for a better guide.'}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Authentication Notice for Anonymous Users */}
                    {!user && (
                      <div className="mb-6 p-4 bg-blue-50 border border-blue-100 rounded-lg">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-blue-800">Sign in required to save</h3>
                            <div className="mt-2 text-sm text-blue-700">
                              <p>You'll need to sign in or create an account to save and process this recording. Don't worry, your recording will be preserved during sign-in.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Action Buttons */}
                    <div className="grid grid-cols-3 gap-4">
                      <button
                        onClick={handleProcessRecording}
                        className="flex flex-col items-center justify-center p-4 bg-blue-50 hover:bg-blue-100 rounded-lg transition-colors"
                      >
                        <svg className="h-8 w-8 text-blue-600 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                        <span className="font-medium text-blue-700">{user ? "Process" : "Sign in & Save"}</span>
                        <span className="text-xs text-gray-500 mt-1">{user ? "Create guide" : "Continue to save"}</span>
                      </button>
                      
                      <button
                        onClick={startRecording}
                        className="flex flex-col items-center justify-center p-4 bg-yellow-50 hover:bg-yellow-100 rounded-lg transition-colors"
                      >
                        <svg className="h-8 w-8 text-yellow-600 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        <span className="font-medium text-yellow-700">Re-record</span>
                        <span className="text-xs text-gray-500 mt-1">Start over</span>
                      </button>
                      
                      <button
                        onClick={handleDropGuide}
                        className="flex flex-col items-center justify-center p-4 bg-red-50 hover:bg-red-100 rounded-lg transition-colors"
                      >
                        <svg className="h-8 w-8 text-red-600 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                        <span className="font-medium text-red-700">Discard</span>
                        <span className="text-xs text-gray-500 mt-1">Delete recording</span>
                      </button>
                    </div>
                  </div>
                ) : recordingCompleted ? (
                  // New post-recording options UI
                  <div className="text-center py-8">
                    <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-green-100 mb-6">
                      <svg className="h-10 w-10 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <h2 className="text-2xl font-medium text-gray-900 mb-4">
                      Recording Complete!
                    </h2>
                    <p className="text-gray-600 mb-8">
                      Your guide "{recordingData?.title || 'New Guide'}" has been created. What would you like to do with it?
                    </p>
                    
                    <div className="grid grid-cols-3 gap-4">
                      <button
                        onClick={handleKeepGuide}
                        className="flex flex-col items-center justify-center p-4 bg-blue-50 hover:bg-blue-100 rounded-lg transition-colors"
                      >
                        <svg className="h-8 w-8 text-blue-600 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span className="font-medium text-blue-700">Keep Guide</span>
                        <span className="text-xs text-gray-500 mt-1">View your guide</span>
                      </button>
                      
                      <button
                        onClick={handleRestartRecording}
                        className="flex flex-col items-center justify-center p-4 bg-yellow-50 hover:bg-yellow-100 rounded-lg transition-colors"
                      >
                        <svg className="h-8 w-8 text-yellow-600 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        <span className="font-medium text-yellow-700">Restart</span>
                        <span className="text-xs text-gray-500 mt-1">Record a new version</span>
                      </button>
                      
                      <button
                        onClick={handleDropGuide}
                        className="flex flex-col items-center justify-center p-4 bg-red-50 hover:bg-red-100 rounded-lg transition-colors"
                      >
                        <svg className="h-8 w-8 text-red-600 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                        <span className="font-medium text-red-700">Drop Guide</span>
                        <span className="text-xs text-gray-500 mt-1">Delete this recording</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="inline-flex items-center justify-center w-20 h-20 bg-blue-100 rounded-full text-blue-600 mb-6">
                      {isRecording ? (
                        <button
                          onClick={stopRecording}
                          className="w-full h-full rounded-full bg-red-500 hover:bg-red-600 transition-colors duration-300 flex items-center justify-center text-white"
                          disabled={isProcessing}
                        >
                          <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z" />
                          </svg>
                        </button>
                      ) : (
                        <button
                          onClick={startRecording}
                          className="w-full h-full rounded-full bg-blue-600 hover:bg-blue-700 transition-colors duration-300 flex items-center justify-center text-white"
                          disabled={isProcessing}
                        >
                          <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                          </svg>
                        </button>
                      )}
                    </div>
                    <h2 className="text-2xl font-medium text-gray-900 mb-4 tracking-tight">
                      {isRecording ? "Recording in Progress" : "Start Recording"}
                    </h2>
                    <p className="text-gray-600 font-light leading-relaxed mb-8">
                      {isRecording 
                        ? "Click the stop button or end screen share to finish recording."
                        : "Click the record button to start capturing your screen and audio. Share your knowledge by creating an interactive guide."}
                    </p>

                    {!isRecording && (
                      <div className="mt-6 border-t border-gray-100 pt-6">
                        <div className="mb-4">
                          <label className="flex items-center space-x-2 text-sm text-gray-700">
                            <input
                              type="checkbox"
                              checked={isPublic}
                              onChange={(e) => {
                                setIsPublic(e.target.checked);
                                if (e.target.checked) {
                                  setSelectedChannels([]);
                                }
                              }}
                              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <span>Make this recording public</span>
                          </label>
                        </div>

                        {!isPublic && (
                          <ChannelSelector
                            onChannelSelect={setSelectedChannels}
                            selectedChannels={selectedChannels}
                            className="mt-4"
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
} 