import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../ui/Button";

export function ShowcaseSection() {
  return (
    <section className="w-full py-24 bg-gray-50">
      <div className="max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center text-center mb-16">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
            Legacy Software Made Simple
          </h2>
          <p className="mt-4 text-xl text-gray-500 max-w-[800px]">
            See how ShowMeHow transforms complex Bloomberg Terminal operations into intuitive, step-by-step guides
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <h3 className="text-2xl font-bold">Bloomberg Terminal Guide</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <svg className="h-6 w-6 text-blue-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span>Interactive step-by-step guidance for complex financial operations</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-blue-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span>Real-time AI assistance for troubleshooting</span>
              </li>
              <li className="flex items-start">
                <svg className="h-6 w-6 text-blue-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span>Reduce training time from weeks to hours</span>
              </li>
            </ul>
            <div className="pt-4 flex gap-4">
              <div className="flex-1 max-w-[240px]">
                <Link to="/guide">
                  <Button className="w-full h-12 text-base bg-blue-600 text-white hover:bg-blue-700">
                    Show Them How
                  </Button>
                </Link>
              </div>
              <div className="flex-1 max-w-[240px]">
                <Link to="/learn">
                  <Button variant="outline" className="w-full h-12 text-base border-2">
                    Show Me How
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <div className="relative rounded-xl overflow-hidden shadow-2xl aspect-video">
            <iframe
              className="w-full h-full absolute inset-0"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/A9fR9TgxGwc?si=rYP_1HT12lBVKoZQ"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
} 