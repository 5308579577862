import { supabase } from '../lib/supabase';

export async function analyzeVideo(blob: Blob): Promise<string> {
  try {
    // Convert blob to base64
    const base64data = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = (reader.result as string).split(',')[1];
        resolve(base64);
      };
      reader.readAsDataURL(blob);
    });

    const response = await fetch('https://sksq96--video-analysis-app-analyze-video.modal.run', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        video: base64data
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.analysis;
  } catch (error) {
    console.error('Error analyzing video:', error);
    throw error;
  }
}

export async function generateMetadata(transcript: string): Promise<{ 
  title: string; 
  description: string;
  steps: string[];
}> {
  try {
    // Call the Supabase Edge Function that uses OpenAI with the exact prompt:
    // "You are an AI that generates titles, descriptions, and step-by-step guides for video content. Respond in this exact format:
    // TITLE: <3-4 word title without quotes>
    // DESCRIPTION: <one sentence summary, max 100 characters>
    // STEPS:
    // - <step 1>
    // - <step 2>
    // ..."
    const { data, error } = await supabase.functions.invoke('generate-title', {
      body: { transcript }
    });

    if (error) {
      console.error("Error generating metadata:", error);
      throw error;
    }

    return {
      title: data.title || "New Guide Recording",
      description: data.description || "A recorded guide showing how to accomplish a task.",
      steps: data.steps || []
    };
  } catch (error) {
    console.error("Error in generateMetadata:", error);
    // Return default values if the function call fails
    return {
      title: "New Guide Recording",
      description: "A recorded guide showing how to accomplish a task.",
      steps: []
    };
  }
} 