import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";
import { useAuth } from "../../contexts/AuthContext";

export function Header() {
  const [featuresOpen, setFeaturesOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const profileRef = useRef<HTMLDivElement>(null);

  // Close profile dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setIsProfileOpen(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileRef]);

  // Handle sign out
  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/learn');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Get user display name (first part of email or full email)
  const getDisplayName = () => {
    if (!user?.email) return 'User';
    return user.email.split('@')[0];
  };

  // Get user initials for avatar
  const getUserInitials = () => {
    if (!user?.email) return 'U';
    const name = user.email.split('@')[0];
    return name.charAt(0).toUpperCase();
  };

  return (
    <div className="w-full border-b">
      <div className="max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8">
        <header className="h-14 flex items-center">
          <Link to="/learn" className="flex items-center">
            <span className="font-bold text-xl">ShowMeHow</span>
          </Link>
          
          {/* Push everything to the right */}
          <div className="ml-auto flex items-center space-x-8">
            <div className="relative">
              <button 
                className="text-sm font-medium flex items-center gap-1"
                onClick={() => setFeaturesOpen(!featuresOpen)}
              >
                Features
                <svg 
                  width="12" 
                  height="12" 
                  viewBox="0 0 12 12" 
                  fill="none" 
                  className={`transition-transform ${featuresOpen ? 'rotate-180' : ''}`}
                >
                  <path d="M2 4L6 8L10 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
              
              {featuresOpen && (
                <div className="absolute top-full left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                  <Link 
                    to="/learn"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                    onClick={() => setFeaturesOpen(false)}
                  >
                    Show Me How
                  </Link>
                  <Link 
                    to="/guide"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                    onClick={() => setFeaturesOpen(false)}
                  >
                    Show Them How
                  </Link>
                </div>
              )}
            </div>

            <Link to="/pricing" className="text-sm font-medium">
              Pricing
            </Link>
            <a 
              href="https://cal.com/andrewsiah+irismeng/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-sm font-medium"
            >
              Contact
            </a>

            {/* User Profile Menu */}
            {user ? (
              <div className="relative" ref={profileRef}>
                <button
                  onClick={() => setIsProfileOpen(!isProfileOpen)}
                  className="flex items-center focus:outline-none"
                  aria-expanded={isProfileOpen}
                  aria-haspopup="true"
                >
                  <span className="hidden md:block mr-2 text-sm font-medium text-gray-700">
                    {getDisplayName()}
                  </span>
                  <div className="h-8 w-8 rounded-full bg-blue-600 text-white flex items-center justify-center text-sm font-medium">
                    {getUserInitials()}
                  </div>
                </button>

                {/* Profile Dropdown */}
                {isProfileOpen && (
                  <div className="absolute right-0 w-48 mt-2 py-2 bg-white rounded-md shadow-lg z-20">
                    <div className="px-4 py-2 border-b border-gray-100">
                      <p className="text-sm font-medium text-gray-900">{user.email}</p>
                      <p className="text-xs text-gray-500 truncate">Account Settings</p>
                    </div>
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setIsProfileOpen(false)}
                    >
                      <div className="flex items-center">
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        Your Profile
                      </div>
                    </Link>
                    <Link
                      to="/channels/manage"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setIsProfileOpen(false)}
                    >
                      <div className="flex items-center">
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                        </svg>
                        My Channels
                      </div>
                    </Link>
                    <div className="border-t border-gray-100 mt-1"></div>
                    <button
                      onClick={handleSignOut}
                      className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                    >
                      <div className="flex items-center">
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        Sign out
                      </div>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link to="/signin">
                  <Button variant="outline" className="text-blue-600 border-blue-600 hover:bg-blue-50">
                    Sign In
                  </Button>
                </Link>
                <Link to="/signup">
                  <Button className="bg-blue-600 text-white hover:bg-blue-700">
                    Sign Up
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </header>
      </div>
    </div>
  );
} 