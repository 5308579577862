import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../ui/Button";

export function Hero() {
  const [imageError, setImageError] = useState(false);

  return (
    <section className="w-full py-8 sm:py-12 md:py-16 lg:py-24">
      <div className="max-w-[1280px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid gap-6 lg:grid-cols-[1fr_500px] lg:gap-12 xl:grid-cols-[1fr_700px]">
          <div className="flex flex-col justify-center space-y-6 sm:space-y-8">
            <div className="space-y-4 sm:space-y-6">
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tighter xl:text-6xl/none text-slate-900">
                One AI Guide is Worth a Thousand Manuals
              </h1>
              <p className="text-lg sm:text-xl text-slate-600 md:text-2xl">
                Transform implicit knowledge into interactive, AI-powered guides for teams, customers, and legacy systems.
              </p>
            </div>
            <div className="flex flex-col gap-3 sm:flex-row sm:gap-4 max-w-md mx-auto sm:mx-0 w-full">
              <Link to="/guide" className="flex-1 sm:flex-initial sm:min-w-[160px]">
                <Button 
                  className="w-full px-6 h-14 sm:h-12 text-base sm:text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700 rounded-xl sm:rounded-lg shadow-lg hover:shadow-xl transition-all duration-200 flex items-center justify-center gap-2"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                  </svg>
                  Show Them How
                </Button>
              </Link>
              <Link to="/learn" className="flex-1 sm:flex-initial sm:min-w-[160px]">
                <Button 
                  variant="outline" 
                  className="w-full px-6 h-14 sm:h-12 text-base sm:text-sm font-semibold border-2 border-slate-200 text-slate-900 hover:bg-slate-100 rounded-xl sm:rounded-lg shadow-md hover:shadow-lg transition-all duration-200 flex items-center justify-center gap-2"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  Show Me How
                </Button>
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-center mt-8 lg:mt-0">
            <div className="relative w-full max-w-[400px] sm:max-w-[500px] md:max-w-[600px] lg:max-w-[700px] aspect-[4/3]">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg transform rotate-3 opacity-20"></div>
              <div className="absolute inset-0 bg-white rounded-lg shadow-lg overflow-hidden">
                <img 
                  src="/assets/New_Project.gif"
                  alt="ShowMeHow Demo" 
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    console.error('Image failed to load:', e);
                    setImageError(true);
                  }}
                />
                {imageError && (
                  <div className="absolute inset-0 flex items-center justify-center text-slate-500">
                    <p>Image failed to load. Please check the console for details.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
} 